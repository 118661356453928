export default function() {
  /* 表格列头 */
  return [
    { label: "序号", type: "index", width: 80 },
    { label: "店铺名称", prop: "store_id", width: 200, slots: "store_id" },
    // { label: "店铺名称", prop: "store_name", width: 200 },
    { label: "会员卡类型", prop: "type", slots: "type", width: 100 },
    { label: "会员卡号", prop: "vip_number", width: 120 },
    { label: "会员手机号", prop: "user_phone", width: 120 },
    // { label: "核销人手机号", prop: "cancel_phone", width: 120 },
    { label: "金额", prop: "amount", slots: "amount" },
    { label: "交易描述", prop: "message", width: 200 },
    {
      label: "交易时间",
      prop: "create_time",
      width: 180,
      // slots: "state",
    },
    { label: "核销人", prop: "cancel_id", width: 300 },
    {
      label: "操作",
      prop: "operation",
      width: 100,
      slots: "operation",
      // fixed: "right",
    },
  ];
}
